<template>
  <div class="content-page">
    <div class="header-title">
      <div>TRANSACTION HISTORY</div>
      <font-awesome-icon
        :icon="['fas', 'sliders-h']"
        class="mr-3 filter-icon"
        @click="showFilter = true"
      />
    </div>
    <div class="container">
      <div
        class="d-inline-flex w-100 justify-content-between align-items-center mb-2"
      >
        <div class="f-16">{{ showFilterText }}</div>
        <div class="f-13" @click="handleSeeAll">
          {{ isSeeAll ? "See all" : "See less" }}
        </div>
      </div>
      <template v-if="!isPageLoad">
        <div class="container-list-item" ref="containerList">
          <template v-if="data.length > 0">
            <b-card
              style="border-radius: 8px"
              v-for="list of data"
              :key="'trasaction-' + list.transaction_id"
              class="mb-2"
            >
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <div class="f-14 text-bold">
                    {{ $moment(list.transaction_date).format("MMM DD YYYY") }}
                  </div>
                  <div class="f-13">#{{ list.invoice_no }}</div>
                </div>
                <div>
                  <b-badge pill class="f-11 py-2" variant="custom"
                    >{{ list.total_item }} items</b-badge
                  >
                </div>
                <div class="text-right">
                  <div class="f-13 text-price">
                    ฿{{ list.total_spend | numeral("0,0.00") }}
                  </div>
                  <div class="f-13" @click="viewRecipt(list.transaction_id)">
                    View Detail
                  </div>
                </div>
              </div>
            </b-card>
            <div class="text-center" v-show="isAllPage">No More Data</div>
            <div class="text-center" v-show="isLoading">
              <b-spinner small label="Small Spinner"></b-spinner> Loading
            </div>
          </template>
          <div v-else><b-card class="text-center p-3">No Data</b-card></div>
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          <b-spinner small label="Small Spinner"></b-spinner> Loading
        </div>
      </template>
      <div class="dropdown-backdrop" v-if="showFilter"></div>
      <div class="filter-panel" v-if="showFilter">
        <div class="d-inline-flex f-16 w-100 mb-3">
          <div
            class="text-bold"
            style="flex: 0 1 45%"
            @click="showFilter = false"
          >
            Close
          </div>
          <div class="" style="flex: 1">Filter</div>
        </div>

        <div class="filter-container">
          <div
            @click="handleFilter('This month', 0)"
            :class="[
              'filter-chioce',
              { isActive: isShow == 0 && !filter.all_flag }
            ]"
          >
            This month
            <font-awesome-icon
              :icon="['fas', 'check']"
              class="ml-auto"
              v-if="isShow == 0 && !filter.all_flag"
            />
          </div>
          <div
            @click="handleFilter('3 months ago', 3)"
            :class="['filter-chioce', { isActive: isShow == 3 }]"
          >
            3 months ago
            <font-awesome-icon
              :icon="['fas', 'check']"
              class="ml-auto"
              v-if="isShow == 3"
            />
          </div>
          <div
            @click="handleFilter('6 months ago', 6)"
            :class="['filter-chioce', { isActive: isShow == 6 }]"
          >
            6 months ago
            <font-awesome-icon
              :icon="['fas', 'check']"
              class="ml-auto"
              v-if="isShow == 6"
            />
          </div>

          <div
            @click="handleFilter('All Transaction', 0, true)"
            :class="[
              'filter-chioce',
              { isActive: isShow == 0 && filter.all_flag }
            ]"
          >
            All Transaction
            <font-awesome-icon
              :icon="['fas', 'check']"
              class="ml-auto"
              v-if="isShow == 0 && filter.all_flag"
            />
          </div>
        </div>
        <b-button
          class="w-100 btn-register"
          type="button"
          @click="updateFilter()"
          >UPDATE FILTER
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: 0,
      showFilter: false,
      filter: {
        month_ago: 0,
        all_flag: true,
        page: 1,
        pageSize: 10
      },

      isSeeAll: true,
      data: [],
      isLoading: false,
      pageDetail: {
        page: 1,
        pageSize: 10,
        total: 0,
        allPage: 0
      },
      isAllPage: false,
      isUpdateFilter: false,
      filterText: "",
      showFilterText: "All transaction",
      isPageLoad: true
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    eventScroll() {
      const masonry = document.getElementsByClassName("container-list-item")[0];
      console.log(document.getElementsByClassName("container-list-item"));
      masonry.addEventListener("scroll", () => {
        if (masonry.scrollTop + masonry.clientHeight == masonry.scrollHeight) {
          this.getNextBatch(30);
        }
      });
    },
    handleFilter(text, val, flag = false) {
      this.filter.month_ago = val;
      this.filter.all_flag = flag;
      this.isShow = val;
      this.filterText = text;
    },
    async updateFilter() {
      document.querySelector(".container-list-item").scrollTop = 0;
      this.filter.page = 1;
      this.getData();
      this.showFilterText = this.filterText;
      this.showFilter = false;
    },
    getData() {
      this.isAllPage = false;
      this.isPageLoad = true;
      this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/Transaction/list_customer_transaction`,
          this.filter
        )
        .then(async data => {
          this.data = data.detail.transaction_list;
          this.pageDetail = data.detail;
          this.isPageLoad = false;
          this.$nextTick(() => this.eventScroll());
        })
        .catch(err => {
          console.log(err);
          this.isPageLoad = false;
        });
    },
    async getNextBatch() {
      if (this.isLoading == true) {
        return;
      }
      if (this.filter.page == this.pageDetail.allPage) {
        return (this.isAllPage = true);
      }
      this.isLoading = true;
      this.filter.page = this.filter.page + 1;
      const resp = await this.$axios.post(
        `${process.env.VUE_APP_API}/api/Transaction/list_customer_transaction`,
        this.filter
      );

      setTimeout(() => {
        this.isLoading = false;
        this.data = [...this.data, ...resp.detail.transaction_list];
        // this.pageDetail = resp.detail;
      }, 1000);
    },
    viewRecipt(id) {
      return this.$router.push("/transaction-summary/" + id);
    },
    handleSeeAll() {
      this.isSeeAll = !this.isSeeAll;
      if (this.isSeeAll) {
        this.filter.pageSize = this.pageDetail.total || 99999;
        const masonry = document.getElementsByClassName(
          "container-list-item"
        )[0];

        masonry.removeEventListener(
          "scroll",
          () => {
            if (
              masonry.scrollTop + masonry.clientHeight ==
              masonry.scrollHeight
            ) {
              this.getNextBatch(30);
            }
          },
          false
        );
      } else {
        this.eventScroll();
      }
      this.getData();
    }
  }
};
</script>

<style lang="scss" scoped>
.header-title {
  font-weight: 700;
  font-size: 16px;
  margin: 1.25rem 0;
  display: inline-flex;
  align-items: center;
  letter-spacing: 1px;

  justify-content: center;
  width: 100%;
  position: relative;
}
.filter-icon {
  position: absolute;
  width: 24px !important;
  height: auto;
  aspect-ratio: 1;
  right: 0;
}
.text-price {
  color: #b00020;
  font-weight: 500;
}
.badge-custom {
  color: black;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.1);
}
.filter-panel {
  width: 100%;
  height: 40vh;
  position: fixed;
  z-index: 50;
  background: white;
  bottom: 0;
  left: 0;
  border-radius: 0.75rem;
  padding: 1.25rem;
}
@media screen and (max-height: 700px) {
  .filter-panel {
    height: 50vh;
  }
}
.dropdown-backdrop {
  height: 100vh;
  width: 100%;
  background: #00000042;
  position: fixed;
  top: 0;
  left: 0;
}
.filter-container {
  display: flex;
  height: 70%;
  flex-direction: column;
}
.filter-chioce {
  &.isActive {
    background-color: rgba(239, 239, 239, 1);
  }
  margin-bottom: 0.25rem;
  padding: 0.5rem;
  border-radius: 8px;
  color: black;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.container-list-item {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 2rem;
}
</style>
